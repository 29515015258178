.footer{
	position: fixed;
	bottom: 0;
	left: 200px;
	width: calc(100% - 200px);
	background: #fff;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #000000;
	padding: 12px;
	border-top: 1px solid #000000;
}

.footer > p{
	margin: 0 0 8px;
}

.versionContainer > label {
	margin-right: 12px;
}
