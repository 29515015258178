.addCompanyLink {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: "Roboto";
  border: 1px solid #000;
  transition: border 0.3s;
  color: #000;
  margin-left: 10px;
  border-radius: 5px;
}

.addCompanyLink:hover {
  border: 1px solid #1677ff;
}

.adminButton {
  width: 90px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: "Roboto";
  border: 1px solid #000;
  transition: 0.3s;
  color: #000;
  margin-right: 10px;
  border-radius: 5px;
}

.adminButton:hover {
  border: 1px solid #1677ff;
  color: #1677ff;
}

.subheaderWrapper {
  display: flex;
  justify-content: space-between;
}

.siteLayout{
  padding-bottom: 66px;
}

.contentWrapper{
  padding: 24px 0;
  min-height: 360px;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
