.blurDiv {
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(86, 86, 86, 0.7);
  backdrop-filter: blur(5px);
}

.content {
  background-color: white;
  border-radius: 20px;
  width: fit-content;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
}

.content > h1 {
  margin: 0 0 30px;
  color: black;
  font-size: 30px;
}

.content > p {
  margin: 0 0 30px;
  text-align: center;
  color: black;
  font-weight: 600;
  font-size: 18px;
}

.content > button {
  cursor: pointer;
  text-decoration: none;
  color: white;
  background-color: #001529;
  font-size: 20px;
  width: fit-content;
  padding: 10px 20px;
  font-weight: 700;
  border-radius: 10px;
  border: none;
}
