.App {
  text-align: center;
}

/* Creation form pages */

.container {
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 30px;
  background-color: rgb(144, 170, 192);
  height: fit-content;
  box-shadow: 0 0 8px 2px #9c9c9c;
}

.container > form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container > form > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}
.formRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 35px;
}

.container > form > div > p {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  min-width: 170px;
}
.inputContainer {
  width: 100%;
  margin-bottom: -15px;
}
.errorMessage {
  display: block;
  color: red;
  height: 15px;
  font-size: 14px;
}
.inputContainer > input,
.inputContainer > textarea {
  width: 100%;
  outline: none;
  box-sizing: border-box;
  padding: 15px 20px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  resize: none;
  font-family: "Roboto";
  border-right: 16px solid transparent;
}

.container > form > div > input,
.container > form > div > select,
.container > form > div > textarea {
  width: 100%;
  outline: none;
  box-sizing: border-box;
  padding: 15px 20px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  resize: none;
  font-family: "Roboto";
  border-right: 16px solid transparent;
}

.container > form > button {
  width: fit-content;
  outline: none;
  box-sizing: border-box;
  padding: 15px 20px;
  border: none;
  border-radius: 10px;
  font-size: 22px;
  align-self: center;
  margin-top: 15px;
  cursor: pointer;
}

.container > form > .invalidButton {
  color: rgb(157, 157, 157);
  opacity: 0.75;
  cursor: default;
}

.invalidField {
  background-color: rgb(213, 156, 156);
}
.mapContainer {
  display: flex;
  width: 100%;
}
