.sidebar > div {
	position: fixed;
	width: 200px;
	z-index: 5;
}

.logoContainer{
	display: flex;
	align-items: center;
	justify-content: center;
	background: #FFFFFF;
	margin: 20px 30px 20px;
	padding: 6px;
	border-radius: 6px;
	overflow: hidden;
}

.logoContainer > img{
	width: 100%;
}

.menu {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-end;
}

.companyLinkWrapper {
	align-self: stretch;
}

.link {
	width: calc(100% - 6px);
	box-sizing: border-box;
	display: block;
	padding: 15px 30px;
	font-size: 16px;
	color: rgb(170, 170, 170);
	text-decoration: none;
	white-space: nowrap;
	overflow-x: hidden;
}

.active {
	color: white;
	background-color: rgb(10, 74, 212);
	border-radius: 15px 0 0 15px;
}

.active:hover {
	color: white;
}

.companyStatus {
	color: white;
	font-size: 16px;
	margin: 0;
	padding-bottom: 5px;
}

.profileContainer{
	padding: 0 8px 16px 16px;
	color: #fff;
	margin-top: auto;
	position: absolute;
	bottom: 46px;
	left: 0;
	width: 100%
}

.profileRow{
	display: flex;
	align-items: center;
}

.profileContainer > h3{
	margin: 0 0 8px 0;
	padding: 0;
}

.profileCircle{
	padding: 20px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #0f6c83;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;
	font-weight: bold;
	margin-right: 6px;
	font-size: 24px;
}

.profileRow > span{
	font-size: 16px;
}

.logoutBtn {
	outline: none;
	border: none;
	position: fixed;
	bottom: 0;
	z-index: 1;
	height: 48px;
	color: #fff;
	line-height: 48px;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	background: rgb(110, 26, 26);
	cursor: pointer;
	border-radius: 15px 0 0 15px;
	width: 190px;
	margin-left: 10px;
}
