.companyCard {
	position: relative;
	background: #ffffff;
	border: 0.5px solid #a3a3a3;
	padding: 9px 36px 24px;
	margin-bottom: 10px;
}

.companyCard.premium {
	background-color: #FFD3D3;
}

.companyCard > div:last-child > a {
	margin-right: 10px;
}

.companyCard > div > div > span:first-child {
	display: block;
	text-align: right;
	margin-right: 38px;
	width: 80px;
	min-width: 80px;
	font-weight: bold;
}


.companyView > div > div {
	font-family: "Roboto", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #000000;
	margin-bottom: 3px;
	display: flex;
}

.companyView > div > div:last-child {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.companyView {
	display: flex;
	align-items: center;
}

.companyLogo {
	width: 150px;
	max-height: 200px;
	margin-right: 24px;
}

.companySubscriptionBlock{
	display: block !important;
	padding-bottom: 8px;
	border-bottom: 1px solid #9c9c9c;
}

.companySubscriptionBlock > h1{
	margin-top: 0;
	margin-bottom: 8px;
}

.companySubscriptionTitle{
	text-transform: capitalize;
}

.companySubscriptionLocations{
	display: flex;
	align-items: flex-start;
}

.companySubscriptionLocationsList{
	margin-right: 32px;
}

.companySubscriptionLocationsList > p{
	margin-bottom: 2px;
	margin-top: 0;
	text-decoration: underline;
}

.companySubscriptionLabelValues{
	margin-left: 12px;
}

.companySubscriptionLabelValues > p{
	margin: 0;
}

.companySubscriptionLabelValues span:first-child{
	margin-right: 8px;
}

.changeStatusBtns {
	margin-left: auto;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.changeStatusBtns > button {
	cursor: pointer;
	outline: none;
	border: none;
	font-size: 14px;
	font-weight: bold;
	color: white;
	border-radius: 7px;
	background: rgb(9, 110, 9);
	width: 100px;
	height: 32px;
}

.changeStatusBtns > button:nth-child(2) {
	background: rgb(226, 62, 62);
}

.disabledBtn {
	opacity: 0.6;
	cursor: default !important;
}

.updateMembershipBtn {
	background: #6c6cff !important;
	height: 45px !important;
}

.loaderWrapper {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background: rgba(146, 146, 146, 0.7);
	backdrop-filter: blur(1px);
}

.loader {
	border: 7px solid #f3f3f3;
	border-radius: 50%;
	border-top: 7px solid rgb(10, 74, 212);
	width: 70px;
	height: 70px;
	-webkit-animation: spin 1.5s linear infinite; /* Safari */
	animation: spin 1.5s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
