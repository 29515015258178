.title {
  margin: 0;
  font-size: 22px;
}

.buttonGroup {
  display: flex;
  gap: 10px;
}

.buttonGroup > button {
  cursor: pointer;
  outline: none;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 7px;
  margin-top: 16px;
  padding: 8px 24px;
}

.buttonGroup > .cancelButton {
  background: red;
}

.buttonGroup > .confirmButton {
  background: #428142;
}
