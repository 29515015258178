.pointList {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.pointList > span {
  margin-top: 4px;
}

.pointList > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.pointList > div > input {
  margin-right: 20px;
}

.pointList > div > p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.label {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  min-width: 160px;
}

.checkboxesList {
  display: flex;
  align-items: stretch !important;
}
