.contactDiv {
  width: 30%;
}

.contactDiv > div {
  display: flex;
  margin-bottom: 8px;
}

.contactDiv p {
  font-size: 14px;
  flex-shrink: 0;
  width: 200px;
  margin: 0 0 3px 0;
}

.contactDiv input {
  width: 100%;
  outline: none;
  box-sizing: border-box;
  padding: 15px 15px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
}

.addContact {
  align-self: center;
  width: fit-content !important;
  padding: 10px 14px;
  font-size: 16px;
  background-color: white;
  color: #000;
  cursor: pointer;
  margin-bottom: 40px;
  border-radius: 10px;
}

.deleteContact {
  overflow: hidden;
  position: relative;
  border: none;
  padding: 0;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: transparent;
  color: red;
  font: inherit;
  text-indent: 100%;
  cursor: pointer;
}

.deleteContact:focus {
  outline: solid 0 transparent;
  box-shadow: 0 0 0 2px #8ed0f9;
}

.deleteContact:hover {
  background: rgba(10, 41, 37, 0.1);
}

.deleteContact::after,
.deleteContact::before {
  position: absolute;
  top: 15%;
  left: calc(50% - 0.0625em);
  width: 0.125em;
  height: 70%;
  border-radius: 0.125em;
  transform: rotate(45deg);
  background: currentcolor;
  content: "";
}

.deleteContact::after {
  transform: rotate(-45deg);
}

.contactContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #cbcbcb solid;
  padding-bottom: 35px;
}
.contactNameContainer {
  min-width: 170px;
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 18px;
  font-weight: 700;
}
