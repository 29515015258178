.wrapper {
  display: flex;
  justify-content: space-between;
}

.childContent {
  padding: 7px 5px;
  border-radius: 5px;
  background: #cdd8f3;
}

.childContent > p {
  font-size: 12px;
}
