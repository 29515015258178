.locationCard {
	background: #FFFFFF;
	border: 0.5px solid #A3A3A3;
	padding: 9px 36px 24px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.locationCard>div {
	display: flex;
	flex-direction: column;
}

.locationCard>div>span {
	font-family: 'Roboto', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: flex-start;
	color: #000000;
	width: 260px;
}

.locationCard>div>div span {
	font-family: 'Roboto', serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #000000;
}

.locationCard>div>div>div>div>span:first-child {
	display: inline-block;
	text-align: right;
	margin-right: 38px;
	width: 80px;
	font-weight: bold;
}

.locationCard>div:last-child>a {
	margin-right: 10px;
}

.updateMembershipButton, .editLink{
	margin-top: 12px;
	width: 260px;
}
