.modalContent {
	position: relative;
	background-color: #f5f5f5;
	border-radius: 20px;
	width: fit-content;
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 700px;
	height: 300px;
}
.modalContent > h1 {
	margin: 0;
	font-size: 22px;
}
.closeButton {
	position: absolute;
	cursor: pointer;
	right: 15px;
	top: 15px;
	font-size: 22px;
	color: red;
}

.modalLabel {
	margin-top: 20px;
	font-weight: bold;
	align-self: flex-start;
}

.modalInput {
	margin-top: 5px;
	width: 100%;
	background: white;
	outline: none;
	box-sizing: border-box;
	padding: 15px 20px;
	border-radius: 10px;
	font-size: 18px;
	resize: none;
	font-family: "Roboto";
	border: 1px solid #d1d1d1;
}

.selectWrapper {
	margin-top: 5px;
	width: 100%;
	height: 54px;
	background: white;
	box-sizing: border-box;
	border-radius: 10px;
	font-family: "Roboto";
	border: 1px solid #d1d1d1;
	position: relative;
}

.selectWrapper select {
	border: 0;
	appearance: none;
	background: transparent;
	vertical-align: middle;
	margin: 0;
	font-size: 18px;
	padding-left: 20px;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.selectWrapper select:focus {
	outline: none;
}

.selectWrapper::after {
	position: absolute;
	content: "\2304";
	font-size: 30px;
	line-height: 23px;
	top: 4px;
	right: 10px;
}

.saveButton {
	cursor: pointer;
	outline: none;
	border: none;
	background: #428142;
	color: white;
	font-size: 16px;
	font-weight: bold;
	width: 200px;
	height: 45px;
	border-radius: 7px;
	margin: auto;
}
.disabledBtn {
	opacity: 0.5;
	cursor: auto;
}
