.customButton{
	padding: 8px 12px;
	border: none;
	border-radius: 4px;
	background: #5c9fff;
	width: 100%;
	color: #fff;
	font-weight: 700;
	font-size: 14px;
	cursor: pointer;
	min-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner{
	margin: -8px 0;
	height: 24px
}
