.container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	overflow: hidden;
	background: #f5f5f5;
}

.logoContainer{
	background: #FFFFFF;
	border-radius: 6px;
	width: 130px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.logoContainer > img{
	width: 120px;
}

.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	padding: 10px 20px;
	background: #001529;
}

.popup {
	padding: 0 20px 20px;
	width: 400px;
	height: 250px;
	border: #9c9c9c 1px solid;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #FFFFFF;
}
