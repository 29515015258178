.container {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	color: #000000;
	padding: 3px 0;
	display: flex;
	width: 100%;
}

.container:not(:last-child) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.label {
	display: block;
	margin-right: 6px;
	width: 120px;
}
