.blurDiv {
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(86, 86, 86, 0.7);
  backdrop-filter: blur(5px);
}

.content {
  background-color: white;
  border-radius: 20px;
  width: fit-content;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
}
