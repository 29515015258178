.statusFilter {
	display: flex;
	align-items: center;
	gap: 10px;
}
.statusFilter > p {
	margin: 0;
	font-weight: bold;
}
.statusFilter > select {
	height: 25px;
	width: 110px;
	outline: none;
	border-radius: 5px;
	font-family: "Roboto";
}
