.columnItem {
	background: #e6edfd;
	border-radius: 5px;
	padding: 15px 10px;
}

.columnItem:not(.topLevel) {
	padding: 4px 4px 4px 8px;
}

.columnItem.even {
	background: #cdd8f3;
}

.itemContent {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 7px;
}

.columnItemName {
	margin: 0;
}

.columnItemBtns {
	min-width: 42px;
	display: flex;
	justify-content: flex-end;
	gap: 6px;
}

.itemChildren {
	margin: 5px 0 0 7px;
	display: flex;
	flex-direction: column;
	gap: 2px;
}

.editField {
	color: #139913;
	cursor: pointer;
	font-size: 18px;
}

.deleteFields {
	color: #ff5d5d;
	cursor: pointer;
	fontSize: 18px;
}
