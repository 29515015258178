.linkButton {
  width: fit-content;
  outline: none;
  box-sizing: border-box;
  padding: 15px 20px;
  border: 1px solid #000;
  color: #000;
  border-radius: 10px;
  font-size: 22px;
  align-self: center;
  cursor: pointer;
  transition: border 0.3s;
  margin: 0;
}

.linkButton:hover {
  border: 1px solid #1677ff;
}


.modalWrapper {
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(86, 86, 86, 0.7);
  backdrop-filter: blur(5px);
}
.modalContent {
  position: relative;
  background-color: #f5f5f5;
  border-radius: 20px;
  width: fit-content;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
  height: 300px;
}
.modalContent > h1 {
  margin: 0;
  font-size: 22px;
}
.closeButton {
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 15px;
  font-size: 22px;
  color: red;
}
.modalSelect {
  width: 100%;
  outline: none;
  box-sizing: border-box;
  padding: 15px 20px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  resize: none;
  font-family: "Roboto";
  border-right: 16px solid transparent;
  margin-top: 30px;
}

.modalBtns {
  flex-direction: row;
  margin: 50px 0;
}

.modalBtns >button {
  height: 40px;
  width: 140px;
}

.modalInput{
  width: 100%;
}
