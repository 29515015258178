.banner{
	position: fixed;
	top: 30px;
	z-index: 1000;
	right: 18px;
	padding: 12px;
	min-width: 370px;
	border-radius: 4px;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	animation: __slideInRight 0.2s ease-in;
	display: flex;
	justify-content: space-between;
}

.infoBanner {
	background: #7498f6;
}

.successBanner {
	background: rgba(141, 190, 127, 0.9);
}

.errorBanner {
	background: #e07878;
}

.icon{
	cursor: pointer;
	width: 20px;
	box-sizing: border-box;
	padding: 4px;
	margin-left: 8px;
}


@keyframes __slideInRight {
	from {
		right: -200px;
	}
	to {
		right: 18px;
	}
}
