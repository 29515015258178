.cardContainer{
	padding: 20px;
	border-radius: 8px;
	border: 1px solid #9c9c9c;
	margin-bottom: 12px;
}
.form{
}

.text{
	margin: 6px 0;
	text-align: center;
}

.subscribeButton{
	background: rgb(9, 110, 9);
	position: absolute;
	right: 30px;
	width: 200px;
	bottom: 30px;
	z-index: 200;
}

.getLinkButton{
	background: rgba(212, 222, 212, 0.9);
	border: 1px solid #9c9c9c;
	color: #5e5e5e;
	width: 260px;
}
