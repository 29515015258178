.modalContent {
	position: relative;
	background-color: #f5f5f5;
	border-radius: 20px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 700px;
	height: 480px;
}

.closeButton {
	position: absolute;
	cursor: pointer;
	right: 15px;
	top: 15px;
	font-size: 22px;
	color: red;
}

.changeStatusBtns {
	margin-left: auto;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.changeStatusBtns > button {
	cursor: pointer;
	outline: none;
	border: none;
	font-size: 14px;
	font-weight: bold;
	color: white;
	border-radius: 7px;
	background: rgb(9, 110, 9);
	width: 100px;
	height: 32px;
}

.changeStatusBtns > button:nth-child(1) {
	background: rgb(226, 62, 62);
}

.modalBtns {
	flex-direction: row;
	justify-content: space-between;
	width: calc(100% - 60px);
	position: absolute;
	bottom: 30px;
	right: 0;
	margin: 0 30px;
}

.modalBtns > button {
	height: 40px;
	width: 140px;
}

.modalForm > span{
	display: block;
	margin-bottom: 20px;
}

.modalForm {
	width: 100%;
}

.input {
	width: 26px;
	margin-left: 4px;
}

.cardModal{
	margin-top: 20px;
}

.modalFormHeading{
	margin: 0 auto 20px;
	text-align: center;
}

.modalFormTitle, .modalFormBoldTitle{
	text-decoration: underline;
	margin: 0;
}

.modalFormTitle:first-child{
	margin-top: 26px;
}

.modalFormSubTitle, .modalFormSubTitleBold{
	margin: 8px 0 0 16px;
	display: flex;
	align-items: center;
}

.modalFormBoldTitle{
	margin-top: 20px;
	font-weight: bold;
}

.modalFormSubTitleBold{
	font-weight: bold;
	display: flex;
}

.modalFormSubTitleCount{
	margin-left: 8px;
	font-weight: bold;
}
