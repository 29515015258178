.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 50px;
  border-radius: 20px;
  background-color: #001529;
  width: fit-content;
  margin: auto;
}

.container > h1 {
  margin: 0 0 25px;
  color: white;
  font-size: 30px;
}

.container > input {
  outline: none;
  border: none;
  width: 250px;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 20px;
  margin-bottom: 15px;
}

.container > .button {
  width: fit-content;
  box-sizing: border-box;
  border: none;
  padding: 10px 20px;
  font-size: 20px;
  color: #001529;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}

.container > .newCode {
  color: rgb(212, 212, 212);
  font-size: 12px;
  margin-top: 5px;
  cursor: pointer;
}

.container > p {
  font-size: 16px;
  width: 250px;
  text-align: center;
  margin: 0 0 20px;
  color: white;
}

.container > label {
  margin-top: 12px;
}

.container > label, .container > span{
  color: #9c9c9c;
  font-size: 12px;
}
