.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #001529;
  border-radius: 20px;
  width: fit-content;
  padding: 30px 60px;
}

.container > h1 {
  margin: 0 0 30px;
  color: black;
  font-size: 30px;
}

.container > a {
  text-decoration: none;
  color: white;
  background-color: #001529;
  font-size: 20px;
  width: fit-content;
  padding: 10px 20px;
  font-weight: 700;
  border-radius: 10px;
}
