.linkButton {
  width: fit-content;
  outline: none;
  box-sizing: border-box;
  padding: 15px 20px;
  border: 1px solid #000;
  color: #000;
  border-radius: 10px;
  font-size: 22px;
  align-self: center;
  margin-top: 15px;
  cursor: pointer;
  transition: border 0.3s;
  margin-bottom: 10px;
}

.linkButton:hover {
  border: 1px solid #1677ff;
}



