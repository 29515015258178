.column {
	background: white;
	border-radius: 10px;
	border: 1px #e4e4e4 solid;
	width: 24%;
	min-height: calc(100vh - 215px);
	padding: 0 10px 30px;
	display: flex;
	flex-direction: column;
}

.title {
	text-align: center;
	margin: 8px 0;
	color: #222324;
}

.columnItems {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.addButton {
	cursor: pointer;
	outline: none;
	border: none;
	background: #f5f5f6;
	color: #5d7189;
	font-weight: bold;
	width: 50%;
	height: 40px;
	border-radius: 7px;
	margin: 20px auto;
}
